import React from 'react';

class ErrorPage extends React.Component {
  render() {
    return (
      <div> Under Maintenance </div>
    )
  }
}

export default ErrorPage;
