import React from 'react';

class NotFoundPage extends React.Component {
  render() {
    return (
      <span id='not_found'>Page Not Found!</span>
    )
  }
}

export default NotFoundPage;
